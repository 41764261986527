import { BaseNavigationService } from '../services/base';
import { Component, Input, OnChanges } from '@angular/core';
import { MenuItemModel } from '../models';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NgOptimizedImage } from '@angular/common'
import { Select } from '@ngxs/store';
import { UserState, UserStateModel } from '../store/user/state/user.state';
import { Observable } from 'rxjs';
import { COMMON_DIRECTIVES } from '../shared/basic';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [COMMON_DIRECTIVES, NgOptimizedImage]
})

export class FooterComponent implements OnChanges {
  @Select(UserState)
  public user$: Observable<UserStateModel>;

  public freidaMenuName = 'footer';
  public menus: MenuItemModel[] = []

  @Input() public menuItems: MenuItemModel[];

  constructor(private router: Router,
              private navigationService: BaseNavigationService,
              private authService: AuthService) { }

  public ngOnChanges() {
    if (this.menuItems) {
      this.menus = this.menuItems.filter(m => m.children.length > 0);
    }
  }

  public getAuthStatus() {
    const authCheck = this.user$.subscribe(user => {
      if (!user.user.roles.includes('authenticated')) {
        this.authService.login();
      } else {
        this.router.navigateByUrl('/director');
      }
    });

    authCheck.unsubscribe();
    return false;
  }

  public navigateTo(url: string) {
    if (url.startsWith('http')) {
      location.href = url;
    }
    else if (url === '/director') {
      this.getAuthStatus();

    } else {
      this.router.navigateByUrl(url);
    }
  }

}
