@if (hidden === false) {
  @for (promo of promotions; track promo) {
    <div class="global-banner" data-test="global-banner">
      <ama-banner [icon]="'exclamation-circle'" [buttonText]="promo?.ctaText" (ctaClicked)="ctaButtonClick()" [showCloseButton]="true" (bannerClosed)="closeBanner()"
        [buttonStyle]="'square'" [color]="'primary'">
            {{promo?.promotion}}
      </ama-banner>
    </div>
  }
}
