import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BaseVacantPositionService } from '../../services/base';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VacantPositionModel } from '../../models';

@Injectable()
export class VacantPositionResolver implements Resolve<VacantPositionModel> {
  constructor(
    private router: Router,
    private vacantPositionService: BaseVacantPositionService
  ) { }

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> {
    const nid = route.queryParams.nid;
    const url = state.url;
    return this.vacantPositionService.getVacantPosition(nid || url).pipe(
      catchError(error => {
        return  this.router.navigate(['404']);
      })
    );
  }
}
