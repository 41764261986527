export const MENUS = {
  "product":[
    { "title":"Tools & Resources",
      "url":"route:<nolink>",
      "children":[
        { "title":"Video Gallery",
          "url":"/video-series"
        }
      ]
    },
    { "title":"Program Director Portal",
      "url":"/director",
      "children":[] }
  ],
  "main":[
    { "title":"Explore Specialties",
      "url":"/specialty",
      "children":[]
    },
    { "title":"Tools & Resources",
      "url":"route:<nolink>",
      "children":[
        { "title":"Video Gallery","url":"/video-series" },
        { "title":"Residency Guide","url":"/road-to-residency-guide" },
        { "title":"Residency Calculator","url":"/calculator" },
        { "title":"OBGYN ACI","url":"/residency-alignment?retake=true" },
        { "title":"Contact FREIDA","url":"/contact" }
      ]
    },
    { "title":"Program Director Portal",
      "url":"/director",
      "children":[]
    },
  ],
  "global":[
    { "title":"AMA Home",
      "url":"https://www.ama-assn.org/",
      "children":[]
    },
    { "title":"JAMA Network™",
      "url":"https://jamanetwork.com/",
      "children":[]
    },
    { "title":"FREIDA™",
      "url":"/",
      "children":[] },
    { "title":"AMA Ed Hub™",
      "url":"https://edhub.ama-assn.org/",
      "children":[] },
    { "title":"AMA Insurance",
      "url":"https://www.amainsure.com/",
      "children":[] },
    { "title":"AMA Journal of Ethics®",
      "url":"https://journalofethics.ama-assn.org",
      "children":[] },
    { "title":"CPT®",
      "url":"http://ama-assn.org/practice-management/cpt",
      "children":[]
    },
    { "title":"Store",
      "url":"https://commerce.ama-assn.org/store/ui",
      "children":[]
    }],
  "footer":
    [
      {
        "title":"Column 1",
        "url":"#test",
        "children":[
          { "title":"Vacant Position Listings ",
            "url":"/vacant-position"
          },
          { "title":"Institution Directory",
            "url":"/institution"
          },
          { "title":"Explore Specialties",
            "url":"/specialty"
          }
        ]
      },
      {
        "title":"Column 2",
        "url":"route:<nolink>",
        "children":[
          { "title":"Video Gallery",
            "url":"/video-series"
          },
          { "title":"About FREIDA",
          },
          { "title":"Program Director Portal",
            "url":"/director"
          }
        ]
    },
    {
      "title":"Column 3",
      "url":"route:<nolink>",
      "children":[
        { "title":"Member Benefits",
          "url":"/memberbenefits"
        },
        { "title":"Contact FREIDA",
          "url":"/contact"
        },
        { "title":"FAQ",
          "url":"/faq"
        }
      ]
    }
  ]
};
