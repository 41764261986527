import { AuthService } from '../services';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class MemberGuard {
  constructor(public router: Router,   private store: Store, private authService: AuthService,) {}
  
  public isAuthenticated$ = this.authService.isAuthenticated$;
  
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
    
    this.isAuthenticated$.subscribe((authState: boolean) => {
      // Redirect if not an ama_member
      if (!authState) {
        this.authService.login();
      }
    });
    return true;
  } 
}
