import { EMPTY, Observable } from 'rxjs';
import { PreloadingStrategy, Route } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomPreloading implements PreloadingStrategy {
  public preload(route: Route, load: () => any): Observable<any> {
    if (route.data && route.data.load) {
      return load();
    } else {
      return EMPTY;
    }
  }
}
