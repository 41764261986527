<div class="system-banner-wrapper" [ngClass]="showBanner === 'true' ? 'system-banner-wrapper--show': ''" *ngIf="showBanner === 'true'">
  <div  *ngFor="let promo of promotions">
    <div class="system-banner" *ngIf="promo?.showBanner">
      <ama-icon [size]="'1em'" [name]="'bell'" class="system-banner__icon ama-icon"></ama-icon>

      <div class="system-banner__container">
        <span class="system-banner__message">
          <strong>
            {{promo?.promotion}}
          </strong>
        </span>
      </div>

      <span class="system-banner__close" (click)="closeBanner()">
        <ama-icon [name]="'close'" size="1.25rem" class="ama-icon"></ama-icon>
      </span>
    </div>
  </div>
</div>