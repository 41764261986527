import { CanActivate, CanLoad } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { UserService } from '../services';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardGuard implements CanLoad, CanActivate {

  constructor(private authService: AuthService, private userService: UserService) { }

  public canActivate() {
    return this.checkAuthToken();
  }

  public canLoad() {
    return this.checkAuthToken();
  }

  public checkAuthToken() {
    if (this.authService.getAuthToken() === '') {
      this.authService.login();
    }

    return this.userService
      .getCurrentUser(['roles'])
      .pipe(map(user => !!user));
  }
}

