import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionModel } from '../models';
import { AmaBannerComponent } from '../../lib/components/banner/banner.component';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  imports: [CommonModule, AmaBannerComponent],
  standalone: true
})
export class BannerComponent {
  @Input() public promotions: PromotionModel[];
  @Output() public ctaClicked = new EventEmitter();

  public hidden = false;

  public ctaButtonClick() {
    this.ctaClicked.emit();
  }

  public closeBanner() {
    this.hidden = true;
  }
}
