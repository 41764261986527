import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { Select } from "@ngxs/store";
import { UserState, UserStateModel } from "src/app/store/user/state/user.state";
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services';
import { AsyncPipe, NgOptimizedImage } from '@angular/common'

@Component({
  selector: 'ama-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [AsyncPipe, ButtonComponent, NgOptimizedImage]
})

export class AmaFooterComponent implements OnInit {

  @Select(UserState.isMember)
  public isMember$: Observable<UserStateModel>;
  public isMember;

  @Select(UserState.isAuthenticated)
  public isAuthenticated$: Observable<UserStateModel>;
  public isAuthenticated;

  constructor(private authService: AuthService) { }

  public ngOnInit(): void {
    this.isMember$.subscribe((isAMAMember) => {
      this.isMember = isAMAMember;
    });
    this.isAuthenticated$.subscribe((isLoggedIn) => {
      this.isAuthenticated = isLoggedIn;
    });
  }
  public mission = 'The AMA promotes the art and science of medicine and the betterment of public health.'
  public socialShare = [
    {
      'type': 'facebook',
      'url': 'https://www.facebook.com/AmericanMedicalAssociation'
    },
    {
      'type': 'twitter',
      'url': 'https://twitter.com/AmerMedicalAssn'
    },
    {
      'type': 'linkedin',
      'url': 'https://www.linkedin.com/company/american-medical-association'
    },
    {
      'type': 'youtube',
      'url': 'https://www.youtube.com/user/AmerMedicalAssn'
    },
    {
      'type': 'instagram',
      'url': 'https://www.instagram.com/amermedicalassn/'
    }
  ]

  public amaSites = [
    {
      'url': 'https://www.ama-assn.org//about-ama/ama-career-opportunities',
      'text': 'AMA Careers'
    },
    {
      'url': 'https://www.ama-assn.org//events',
      'text': 'Events'
    },
    {
      'url': 'https://www.ama-assn.org//press-center',
      'text': 'Press Center'
    }
  ]

  public amaAffliates = [
    {
      'url': 'https://www.amaalliance.org/',
      'title': 'AMA Alliance'
    },
    {
      'url': 'http://www.ampaconline.org/',
      'title': 'AMAPAC'
    },
    {
      'url': 'https://amafoundation.org',
      'title': 'AMA Foundation'
    }
  ]

  public amaPartners = [
    {
      'url': 'https://jamanetwork.com/',
      'title': 'JAMA Network™'
    },
    {
      'url': 'https://freida.ama-assn.org',
      'title': 'FREIDA™'
    },
    {
      'url': 'https://edhub.ama-assn.org',
      'title': 'AMA Ed Hub™'
    },
    {
      'url': 'https://www.amainsure.com/index.html',
      'title': 'AMA Insurance'
    },
    {
      'url': 'https://journalofethics.ama-assn.org/home',
      'title': 'AMA Journal of Ethics®'
    },
    {
      'url': 'https://www.ama-assn.org/practice-management/cpt',
      'title': 'CPT®'
    },
    {
      'url': 'https://commerce.ama-assn.org/store/',
      'title': 'Store'
    },
    {
      'url': 'https://info.commerce.ama-assn.org/AMA-credentialing-services-home',
      'title': 'AMA Credentialing Services'
    },
    {
      'url': 'https://innovationmatch.ama-assn.org/',
      'title': 'Physician Innovation Network'
    }
  ]

  public contact = {
    'url': 'https://www.ama-assn.org/form/contact-us',
    'title': 'AMA Contact Us'
  }

  public appStoreLinks = [
    {
      'url': 'https://itunes.apple.com/app/ama-connect/id1355068050?uo=5&amp;at=10l9yE',
      'title': 'iPhone'
    },
    {
      'url': 'https://www.ama-assn.org/store/apps/details?id=org.ama_assn.AMAConnect',
      'title': 'Android'
    }
  ]

  public legalLinks = [
    {
      'url': 'https://www.ama-assn.org/general-information/general-information/terms-use',
      'title': 'Terms of Use'
    },
    {
      'url': 'https://www.ama-assn.org/general-information/general-information/privacy-policy',
      'title': 'Privacy Policy'
    },
    {
      'url': 'https://www.ama-assn.org/general-information/general-information/code-conduct',
      'title': 'Code of Conduct'
    },
    {
      'url': 'https://www.ama-assn.org/general-information/general-information/accessibility-statement',
      'title': 'Website Accessibility'
    }
  ]

  public currentYear = (new Date()).getFullYear();
  public copyright = 'Copyright 1995 - ' + this.currentYear + ' American Medical Association. All rights reserved.'

  public login() {
    this.authService.login();
  }
}
