import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class RedirectGuard implements CanActivate {
  constructor(private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Forces open new window
    window.open(route.data['externalUrl'], '_blank');
    // prevents orignal URL changes
    return false;
  }
}
