<footer class="freida-footer"
        role="contentinfo"
        aria-label="freida footer">
  <div class="freida-footer__container">
    <div class="freida-footer__logo">
      @defer {
      <a href="/"
         aria-describedby="freida-logo">
        <img ngSrc="../../assets/freida-logo.svg"
             alt="freida logo"
             width="109"
             height="50" />
      </a>
      }
    </div>
    <div class="freida-footer__menu-container">
      @for (menu of menus; let index = $index ;track index) {
      <ul class="freida-footer__menu">
        <li>
          @for (link of menu.children; track link) {
          <div *ngIf="link.title=='About FREIDA'">
            <a class="freida-footer__menu__title"
               href="../../assets/images/FreidaAbout.pdf">
              <span>{{ link.title }}</span>
            </a>
          </div>
          <div *ngIf="link.title!='About FREIDA'">
            <a class="freida-footer__menu__title"
               (click)="navigateTo(link.url)"
               routerLinkActive="active">
              <span>{{ link.title }}</span>
            </a>
          </div>
          }
        </li>
      </ul>
      }
    </div>
  </div>
</footer>
