import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class UnauthorizedResponseInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((evt: HttpEvent<any>) => {
        if (evt instanceof HttpErrorResponse) {
          if (evt.status === 401 && this.authService.getAuthToken() === '') {
            this.authService.login();
          }
        }
      }),
      catchError((err: any) => {
        if (err.status === 401 && this.authService.getAuthToken() === '') {
          this.authService.login();
        }

        return of(null);
      })
    );
  }
}
