import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Request} from 'express';

@Injectable()
export class RelativeUrlsInterceptor implements HttpInterceptor {

  constructor(@Optional() @Inject(Request) protected request: Request) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    let serverReq: HttpRequest<any> = req;

    // For now let's intercept the asset/icon/xxx urls to prevent node.js errors.
    if (this.request && req.url.startsWith('assets')) {
      const newUrl = `${this.request.protocol}://${this.request.get('host')}/${req.url}`;
      serverReq = req.clone({url: newUrl});
    }
    return next.handle(serverReq);
  }
}
