import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService, UserService } from '../services';
import { CanActivateFn } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProgramDirectorCanActivate {
  constructor(private authService: AuthService, private userService: UserService) {
  }

  public canActivate(): boolean {
    if (this.authService.getAuthToken() === '') {
      this.authService.login();
      return false;
    }

    return Boolean(this.userService.getCurrentUser(['roles']).pipe(
      map(user => {
        if (user && user.roles && user.roles.includes('program_maintainer')) {
          return Boolean(true);
        } else {
          this.authService.login();
          return Boolean(false);
        }
      })));
  }
}

export const ProgramDirectorGuard: CanActivateFn = (route, state) => {
  return inject(ProgramDirectorCanActivate).canActivate();
};
