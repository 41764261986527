import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class BearerAuthInterceptor implements HttpInterceptor {

  private authenticatedRouteKeywords = [
    '/user',
    '/note',
    '/survey',
    '/program_director_rest',
    'program_downloads',
    'program_maintainers',
    'jwt'
  ];
  constructor(private authService: AuthService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add header for all other authenticated requests
    let authToken = this.authService ? this.authService.getAuthToken() : '';
    authToken = this.authService.newAuthToken ? this.authService.newAuthToken : authToken;

    if (this.requiresAuthHeader(req, authToken)) {
      const newReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
      return next.handle(newReq);
    } else {
      return next.handle(req);
    }
  }

  private requiresAuthHeader (req: HttpRequest<any>, authToken): boolean {
    if (authToken === '') {
      return false;
    }

    const isAuthenticatedRoute = this.authenticatedRouteKeywords.some(
      (keyword) => req.url.includes(keyword)
    );

    if (isAuthenticatedRoute) {
      return true;
    }

    // Subrequests are posts, but bearer tokens are passed into the body's blueprints.
    if (req.method !== 'GET' && !req.url.includes('/subrequests')) {
      return true;
    }

    return false;
  }
}
