import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserService } from '../services';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot) {
    let roles: string[];
    const requiredRoles: string[] = route.data['requiredRoles'] || [];
    if (!requiredRoles.length) {
      return of(true);
    }

    return this.userService
      .getCurrentUser(['roles'])
      .pipe(map(user => {
        if (user) {
          roles = user.roles;
        }
        if (roles && requiredRoles.every(role => roles.includes(role))) {
          return true;
        } else {
          this.router.navigate(['']).then();
          return false;
        }
      }));
  }
}
