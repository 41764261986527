import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BaseFieldsOptionsService } from '../../services/base';

@Injectable()
export class VacantPositionFieldsResolver implements Resolve<any> {

  constructor(private fieldsOptionsService: BaseFieldsOptionsService) { }

  public resolve() {
    return this.fieldsOptionsService.getVacantPositionFields();
  }
}
